import { PopupContextStore } from '@components/common/hoc/PopupProvider';
import useRecommender from '@hooks/components/useRecommender';
import useUserAgent from '@hooks/components/useUserAgent';
import useWindowSize from '@hooks/components/useWindowSize';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import BonusPopup from '../components/BonusPopup';
import CopyCompletePopupContainer from './CopyCompletePopupContainer';

type Props = {};

const BonusPopupContainer = (props: Props) => {
  const navigate = useNavigate();

  const { onPopupShow, onPopupClosed } = useContext(PopupContextStore);
  const { height } = useWindowSize();
  const { code } = useRecommender();
  const { isKakao } = useUserAgent();

  const initailOffset = useMemo(() => (isKakao ? 60 : 0), [isKakao]);

  const [translateY, setTranslateY] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [isMouseClicked, setIsMouseClicked] = useState(false);
  const [isAnimationOn, setIsAnimationOn] = useState(true);

  const copyLink = useMemo(
    () => window.location.origin + `?recommender=${code}`,
    [code],
  );

  const onCopyButtonClicked = useCallback(() => {
    navigator.clipboard
      .writeText(copyLink)
      .then((value) => {
        onPopupShow(<CopyCompletePopupContainer />);
      })
      .catch((err) => {});
  }, [onPopupShow, copyLink, CopyCompletePopupContainer]);

  const onSnsButtonClicked = useCallback(
    (sns: 'insta' | 'twit' | 'facebook' | 'kakao') => {
      // web share api
      // TODO 각 sns별 공유 기능

      switch (sns) {
        case 'facebook':
          window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + copyLink,
          );
          navigate('/');

          return;
        case 'twit':
          window.open(
            'https://twitter.com/intent/tweet?text=' +
              'New Audio Social App Coming Soon%0a' +
              '&url=' +
              copyLink,
          );
          navigate('/');

          return;
        case 'kakao':
          (window as any).Kakao.Link.sendDefault({
            objectType: 'feed',
            content: {
              title: 'IYAGI',
              description: 'New Audio Social App Coming Soon',
              imageUrl: 'https://www.iyagiapp.com/banner.png',
              link: {
                mobileWebUrl: 'https://www.iyagiapp.com',
                webUrl: 'https://www.iyagiapp.com',
              },
            },
            buttons: [
              {
                title: 'Landing Page',
                link: {
                  mobileWebUrl: 'https://www.iyagiapp.com',
                  webUrl: 'https://www.iyagiapp.com',
                },
              },
            ],
            // 카카오톡 미설치 시 카카오톡 설치 경로이동
            installTalk: true,
          });
          navigate('/');

          return;
        case 'insta':
          if (navigator.share) {
            navigator
              .share({
                title: 'New Audio Social App Coming Soon',
                url: copyLink,
              })
              .then(() => {
                console.log('Thanks for sharing!');
              })
              .catch(console.error);
          } else {
            // fallback
            window.open('https://www.instagram.com/');
          }
          navigate('/');

          return;
        default:
          return;
      }
    },
    [copyLink, navigate],
  );

  const onMouseOn = useCallback((e: React.MouseEvent) => {
    setClientY(e.clientY);
    setIsMouseClicked(true);
  }, []);

  const onTouchStart = useCallback((e: React.TouchEvent) => {
    setClientY(e.targetTouches[0].clientY);
    setIsMouseClicked(true);
  }, []);

  const onTouchMove = useCallback(
    (e: React.TouchEvent) => {
      if (isMouseClicked) {
        setTranslateY((prev) => prev + e.targetTouches[0].clientY - clientY);
        setClientY(e.targetTouches[0].clientY);
      }
    },
    [isMouseClicked, clientY],
  );

  const onMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (isMouseClicked) {
        setTranslateY((prev) => prev + e.clientY - clientY);
        setClientY(e.clientY);
      }
    },
    [isMouseClicked, clientY],
  );

  const onModalReset = useCallback(() => {
    setIsAnimationOn(true);
    setTranslateY(initailOffset);
    setTimeout(() => {
      setIsAnimationOn(false);
    }, 310);
  }, [height, initailOffset]);

  const onMouseUp = useCallback(() => {
    console.log(translateY);
    if (translateY > 100) {
      onPopupClosed();
    } else {
      setClientY(0);
    }
    setIsMouseClicked(false);
  }, [translateY]);

  const onTouchEnd = useCallback(() => {
    console.log('touchend');
    if (translateY > 100 + initailOffset) {
      console.log('close');
      onPopupClosed();
    } else {
      console.log('reset');
      onModalReset();
    }
    setIsMouseClicked(false);
  }, [translateY, onPopupClosed, onModalReset, initailOffset]);

  const onModalOpened = useCallback(() => {
    // setClientY(0);
    setTimeout(() => {
      setIsAnimationOn(false);
    }, 500);
  }, []);

  const onWindowMouseUp = (e: MouseEvent) => {
    console.log(translateY);
    if (translateY > 100) {
      onPopupClosed();
    } else {
      console.log('reset');
      onModalReset();
    }
    setIsMouseClicked(false);
  };

  useEffect(() => {
    window.addEventListener('mouseup', onWindowMouseUp);

    return () => {
      window.removeEventListener('mouseup', onWindowMouseUp);
    };
  }, [onWindowMouseUp]);

  useEffect(() => {
    if (onModalOpened) {
      onModalOpened();
    }
    return () => {};
  }, [onModalOpened]);

  useLayoutEffect(() => {
    if (isKakao) {
      setTranslateY(60);
    }

    return () => {};
  }, [isKakao]);

  return (
    <BonusPopup
      copyLink={copyLink}
      isAnimationOn={isAnimationOn}
      translateY={translateY}
      onMouseMove={onMouseMove}
      onMouseOn={onMouseOn}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onCopyButtonClicked={onCopyButtonClicked}
      onSnsButtonClicked={onSnsButtonClicked}
    />
  );
};

export default BonusPopupContainer;
