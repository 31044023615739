import text from '@assets/text';
import NextButton from '@components/common/CButton/NextButton';
import ResultButton from '@components/common/CButton/ResultButton';
import CCheckboxContainer from '@components/common/CCheckbox/containers/CCheckboxContainer';
import useLocalize from '@hooks/components/useLocalize';
import React from 'react';
import '../styles/quiz.last.style.css';

type Props = {
  checkedNumber: number;
  isMobile: boolean;
  onCheckClicked: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onResultButtonClicked: () => void;
};

const checkList = [
  'Everyday',
  'Few times during the month',
  'Whenever you feel like it',
  'Hardly ever',
];

const QuizStep4 = ({
  checkedNumber,
  isMobile,
  onCheckClicked,
  onResultButtonClicked,
}: Props) => {
  const { local } = useLocalize();

  return (
    <div className='quiz-last-root'>
      <div className='content-wrapper'>
        <div className='question'>
          {text[local].quiz.step4.text1}
          {isMobile && <br />} {text[local].quiz.step4.text2}
          <br />
          {text[local].quiz.step4.text3}
        </div>
        <section className='checkbox-wrapper'>
          {text[local].quiz.step4.checkList.map((value, index) => (
            <CCheckboxContainer
              key={index}
              checked={checkedNumber === index}
              id={JSON.stringify(index)}
              name='check'
              onCheckClicked={onCheckClicked}
              label={value}
            />
          ))}
        </section>
      </div>
      <section className='next-button-wrapper'>
        <ResultButton
          disabled={checkedNumber === -1}
          onClick={onResultButtonClicked}
        />
      </section>
    </div>
  );
};

export default QuizStep4;
