import text from '@assets/text';
import useLocalize from '@hooks/components/useLocalize';
import React from 'react';
import images from 'src/assets/images';
import './styles/header.style.css';

type Props = {
  neon: boolean;
  onContactUsClicked: () => void;
  onTakeQuizClicked: () => void;
  onSnsClicked: (type: 'twitter' | 'insta' | 'tictok') => void;
};

const CHeader = ({
  neon,
  onContactUsClicked,
  onSnsClicked,
  onTakeQuizClicked,
}: Props) => {
  const { local } = useLocalize();

  return (
    <nav className='header-root'>
      <section>
        <div className='logo'>IYAGI</div>
        <button
          className='quiz-button'
          onClick={onTakeQuizClicked}
          style={{ zIndex: neon ? 30 : 0 }}>
          <span>{text[local].header.quiz}</span>
          <span className='underline' />
          {neon && (
            <div className='neon-effect'>
              <div className='cloud'></div>
              <div className='neon-hand'></div>
            </div>
          )}
        </button>
        <a className='contact-button' href='mailto:communicia@communicia.com'>
          <span>{text[local].header.contact}</span>
          <span className='underline' />
        </a>
      </section>
      <section className='sns-btns'>
        <button
          className='insta-button'
          onClick={() => onSnsClicked('insta')}
        />
        <button
          className='tictok-button'
          onClick={() => onSnsClicked('tictok')}
        />
        <button
          className='twit-button'
          onClick={() => onSnsClicked('twitter')}
        />
      </section>
    </nav>
  );
};

export default CHeader;
