import React from 'react';
import './styles/checkbox.style.css';

type Props = {
  checked: boolean;
  width: number;
  id: string;
  name: string;
  onCheckClicked: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  containerStyle?: React.CSSProperties;
};

const CCheckbox = ({
  checked,
  onCheckClicked,
  width,
  label,
  id,
  name,
  containerStyle,
}: Props) => {
  return (
    <div className='c-checkbox-root'>
      <input
        className='checkbox'
        type='checkbox'
        name={name}
        id={id}
        checked={checked}
        onChange={onCheckClicked}
      />
      <label htmlFor={id} className='container' style={containerStyle}>
        <div className='check-box'>
          <div className='check' style={{ width: width * 36 }}></div>
        </div>
        <span className='label'>{label}</span>
      </label>
    </div>
  );
};

export default CCheckbox;
