import useQuiz from '@hooks/components/useQuiz';
import useWindowSize from '@hooks/components/useWindowSize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import QuizStep3 from '../components/QuizStep3';

type Props = {};

const QuizStep3Container = (props: Props) => {
  const { __getAnswer, __toNextStep, __storeAnswer } = useQuiz();
  const { width } = useWindowSize();

  const [checkedNumber, setCheckedNumber] = useState(-1);

  const isMobile = useMemo(() => width <= 640, [width]);

  const onCheckClicked = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedNumber(parseInt(e.target.id));
    },
    [__toNextStep],
  );

  const onNextButtonClicked = useCallback(() => {
    __storeAnswer(3, checkedNumber);
    __toNextStep();
  }, [__storeAnswer, checkedNumber]);

  useEffect(() => {
    const answer = __getAnswer();
    setCheckedNumber(answer[3] || -1);
  }, []);

  return (
    <QuizStep3
      checkedNumber={checkedNumber}
      isMobile={isMobile}
      onCheckClicked={onCheckClicked}
      onNextButtonClicked={onNextButtonClicked}
    />
  );
};

export default QuizStep3Container;
