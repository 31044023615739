import React from 'react';
import './styles/bonus.button.style.css';

type Props = {
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const BonusButton = ({
  containerStyle,
  className,
  onClick,
  disabled = false,
}: Props) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`bonus-button-root ${className}`}
      style={containerStyle}>
      <div className='rect'>
        <span className='text'>Share and Get My Bonus!</span>
        <div className='hand'></div>
      </div>
    </button>
  );
};

export default BonusButton;
