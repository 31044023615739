import { turnoffNeon, turnonNeon } from '@store/header/actions';
import { RootState } from '@store/rootReducer';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function useHeader() {
  const { neon } = useSelector((state: RootState) => state.headerReducer);

  const dispatch = useDispatch();

  const __turnOnNeon = useCallback(() => {
    dispatch(turnonNeon());
  }, [turnonNeon]);

  const __turnOffNeon = useCallback(() => {
    dispatch(turnoffNeon());
  }, [turnoffNeon]);

  return {
    neon,
    __turnOffNeon,
    __turnOnNeon,
  };
}
