import React, { useMemo, useState } from 'react';
import Progressbar from '../components/Progressbar';

type Props = {
  progress: number;
};

const ProgressbarContainer = ({ progress }: Props) => {
  const step = useMemo(() => {
    if (progress == 5) {
      return 4;
    } else if (progress === 6) {
      return 0;
    } else {
      return progress;
    }
  }, [progress]);

  return <Progressbar step={step} />;
};

export default ProgressbarContainer;
