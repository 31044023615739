import country from '@assets/country';
import text from '@assets/text';
import BonusButton from '@components/common/CButton/BonusButton';
import CDropdownContainer from '@components/common/CDropdown/container/CDropdownContainer';
import useLocalize from '@hooks/components/useLocalize';
import React from 'react';
import '../styles/bonus.style.css';

type Props = {
  birthList: string[];
  isMobile: boolean;
  width: number;
  validation: boolean;
  onBirthSelected: (option: string) => void;
  onBonusButtonClicked: () => void;
  onCountrySelected: (option: string) => void;
  onGenderSelected: (option: string) => void;
};

const Bonus = ({
  birthList,
  validation,
  isMobile,
  width,
  onBirthSelected,
  onBonusButtonClicked,
  onCountrySelected,
  onGenderSelected,
}: Props) => {
  const { local } = useLocalize();

  return (
    <div className='bonus-root'>
      <section className='text-section'>
        <h1 className='title'>
          {text[local].bonus.title1}
          <br />
          {text[local].bonus.title2}
          <br />
          {text[local].bonus.title3}
        </h1>
        <p className='content-text'>{text[local].bonus.para1}</p>
        <div className='dropdown-wrapper'>
          <CDropdownContainer
            label={text[local].bonus.label1}
            options={birthList}
            onOptionSelected={onBirthSelected}
            width={isMobile ? ((width - 52) * 5) / 11 : 124}
          />
          <CDropdownContainer
            label={text[local].bonus.label2}
            options={country.map((value) => value.name)}
            onOptionSelected={onCountrySelected}
            width={isMobile ? ((width - 52) * 6) / 11 : 196}
          />
          <CDropdownContainer
            label={text[local].bonus.label3}
            options={text[local].bonus.options}
            onOptionSelected={onGenderSelected}
            width={isMobile ? ((width - 52) * 5) / 11 : 156}
          />
        </div>
      </section>
      <section className='button-section'>
        <BonusButton onClick={onBonusButtonClicked} disabled={!validation} />
      </section>
    </div>
  );
};

export default Bonus;
