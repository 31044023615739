import useQuiz from '@hooks/components/useQuiz';
import useWindowSize from '@hooks/components/useWindowSize';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import QuizStart from '../components/QuizStart';

type Props = {};

const QuizStartContainer = (props: Props) => {
  const navigate = useNavigate();

  const { __toNextStep } = useQuiz();
  const { width } = useWindowSize();

  const isMobile = useMemo(() => width <= 640, [width]);

  const onQuizStartClicked = useCallback(() => {
    if (isMobile) {
      navigate('quiz');
    } else {
      __toNextStep();
    }
  }, [navigate, isMobile, __toNextStep]);

  return (
    <QuizStart isMoblie={isMobile} onQuizStartClicked={onQuizStartClicked} />
  );
};

export default QuizStartContainer;
