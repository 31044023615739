import { LocalActionType } from './actions';
import { UPDATE_LOCAL } from './modules/actionTypes';

const initialState: LocalStateType = {
  local: 'english',
};

export type LocalStateType = {
  local: 'korean' | 'english';
};

export default function reducer(
  state = initialState,
  { type, payload }: LocalActionType,
): LocalStateType {
  switch (type) {
    case UPDATE_LOCAL:
      return {
        local: payload,
      };
    default:
      return state;
  }
}
