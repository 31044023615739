import DefaultPopupContainer from '@components/common/DefaultPopup/containers/DefaultPopupContainer';
import useApis from '@hooks/apis/useApis';
import usePopup from '@hooks/components/usePopup';
import useQuiz from '@hooks/components/useQuiz';
import useRecommender from '@hooks/components/useRecommender';
import useWindowSize from '@hooks/components/useWindowSize';
import { EMAIL } from '@lib/regex';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EmailForm from '../components/EmailForm';
import EmailFormMobile from '../components/EmailFormMobile';
import EmailPopupContainer from './EmailPopupContainer';

type Props = {};

const EmailFormContainer = (props: Props) => {
  const navigate = useNavigate();

  const { __showPopup } = usePopup();
  const { width } = useWindowSize();
  const { __registerAnswer } = useApis();
  const { recommender, __updateCode, __updateEmail } = useRecommender();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isMessegeShow, setIsMessegeShow] = useState(false);

  const isMobile = useMemo(() => width <= 640, [width]);
  const validationCheck = useMemo(() => {
    console.log(email, name, EMAIL.test(email), !isMessegeShow);
    if (email && name && EMAIL.test(email) && isMessegeShow) {
      return true;
    } else {
      return false;
    }
  }, [email, name, isMessegeShow]);

  const onCloseButtonClicked = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    [],
  );

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const onInputError = useCallback((correct: boolean) => {
    setIsMessegeShow(correct);
  }, []);

  const onGetResultButtonClicked = useCallback(async () => {
    const { result, message } = await __registerAnswer(
      email,
      name,
      recommender,
    );
    if (!result) {
      __showPopup(<DefaultPopupContainer message={message} />);
      return;
    }

    __updateEmail(email);
    __updateCode(message);
    __showPopup(<EmailPopupContainer />);
  }, [
    __showPopup,
    EmailPopupContainer,
    __updateEmail,
    __registerAnswer,
    __updateCode,
    recommender,
    email,
    name,
  ]);

  if (!isMobile) {
    return (
      <EmailForm
        name={name}
        email={email}
        validationCheck={validationCheck}
        isMessageShow={isMessegeShow}
        onInputError={onInputError}
        onEmailChange={onEmailChange}
        onNameChange={onNameChange}
        onGetResultButtonClicked={onGetResultButtonClicked}
      />
    );
  } else {
    return (
      <EmailFormMobile
        name={name}
        email={email}
        onCloseButtonClicked={onCloseButtonClicked}
        validationCheck={validationCheck}
        isMessageShow={isMessegeShow}
        onInputError={onInputError}
        onEmailChange={onEmailChange}
        onNameChange={onNameChange}
        onGetResultButtonClicked={onGetResultButtonClicked}
      />
    );
  }
};

export default EmailFormContainer;
