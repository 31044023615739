import CHeaderContainer from '@components/common/CHeader/containers/CHeaderContainer';
import HomeContainer from '@components/Home/containers/HomeContainer';
import QuizLayoutContainer from '@components/Quiz/containers/QuizLayoutContainer';
import Test from '@components/Test/Test';
import React from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

type Props = {
  isMobileSize: boolean;
  popup: JSX.Element | null;
  translateY: number;
  onBackdropClicked: () => void;
};

const RootNavigation = ({
  popup,
  translateY,
  isMobileSize,
  onBackdropClicked,
}: Props) => {
  return (
    <BrowserRouter>
      {!isMobileSize && <CHeaderContainer />}
      <Routes>
        <Route path='/' element={<HomeContainer />} />
        <Route path='/test' element={<Test />} />
        <Route path='quiz' element={<QuizLayoutContainer />} />
      </Routes>
      {popup && (
        <div className='popup-container'>
          <div className='backdrop' onClick={onBackdropClicked}></div>
          <div
            className='popup'
            style={{ transform: `translateY(${translateY}vh)` }}>
            {popup}
          </div>
        </div>
      )}
    </BrowserRouter>
  );
};

export default RootNavigation;
