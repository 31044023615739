import { TURN_OFF_NEON, TURN_ON_NEON } from './modules/actionType';

export const turnonNeon = () => {
  return {
    type: TURN_ON_NEON,
  };
};

export const turnoffNeon = () => {
  return {
    type: TURN_OFF_NEON,
  };
};

export type HeaderActionType =
  | ReturnType<typeof turnonNeon>
  | ReturnType<typeof turnoffNeon>;
