import PopupProvider from '@components/common/hoc/PopupProvider';
import WithPopup from '@components/common/hoc/WithPopup';
import useApis from '@hooks/apis/useApis';
import useLocalize from '@hooks/components/useLocalize';
import useQuiz from '@hooks/components/useQuiz';
import useUserAgent from '@hooks/components/useUserAgent';
import useWindowSize from '@hooks/components/useWindowSize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QuizLayout from '../components/QuizLayout';

type Props = {};

const QuizLayoutContainer = (props: Props) => {
  const navigate = useNavigate();

  const { __updateLocal } = useLocalize();
  const { __getIP } = useApis();
  const { isKakao } = useUserAgent();
  const { width } = useWindowSize();
  const { step, __toPrevStep, __initializeStep } = useQuiz();

  const [popupState, setPopupState] = useState(0);

  const isMobile = useMemo(() => width <= 640, [width]);
  const conditionedStep = useMemo(
    () => (isMobile && step === 0 ? step + 1 : step),
    [isMobile, step],
  );

  const onPopupOpened = useCallback(() => {
    setPopupState(1);
  }, []);

  const onPopupClosed = useCallback(() => {
    if (isMobile) {
      navigate('/');
    } else {
      setPopupState(0);
      setTimeout(() => navigate('/'), 300);
    }
  }, [navigate, isMobile]);

  const onStepBackClicked = useCallback(() => {
    __toPrevStep();
  }, [__toPrevStep]);

  useEffect(() => {
    onPopupOpened();

    return () => {
      __initializeStep();
    };
  }, []);

  useEffect(() => {
    __getIP().then((result) => {
      if (result == 'KR') {
        __updateLocal('korean');
      } else {
        __updateLocal('english');
      }
    });
  }, []);

  return (
    <PopupProvider>
      <WithPopup>
        <QuizLayout
          isKakao={isKakao}
          isMobile={isMobile}
          step={conditionedStep}
          popupState={popupState}
          onPopupClosed={onPopupClosed}
          onStepBackClicked={onStepBackClicked}
        />
      </WithPopup>
    </PopupProvider>
  );
};

export default QuizLayoutContainer;
