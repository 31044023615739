import { useLayoutEffect, useState } from 'react';

export default function useUserAgent() {
  const [isKakao, setIsKakao] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    if (window.navigator) {
      if (window.navigator.userAgent.includes('KAKAOTALK')) {
        setIsKakao(true);
      }
    }
  }, []);

  useLayoutEffect(() => {
    if (window.navigator) {
      if (window.navigator.userAgent.includes('mobile')) {
        setIsMobile(true);
      }
    }
  }, []);

  return {
    isMobile,
    isKakao,
  };
}
