import text from '@assets/text';
import useLocalize from '@hooks/components/useLocalize';
import useWindowSize from '@hooks/components/useWindowSize';
import React, { useMemo } from 'react';
import './styles/result.style.css';

type Props = {
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ResultButton = ({
  containerStyle,
  className,
  onClick,
  disabled = false,
}: Props) => {
  const { width } = useWindowSize();
  const { local } = useLocalize();
  const isMobile = useMemo(() => width < 640, [width]);
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`result-button-root ${className}`}
      style={containerStyle}>
      <div className='rect'>
        <span className='text'>
          {text[local].button.result1}
          {!isMobile && <br />}
          {text[local].button.result2}
        </span>
        <div className='hand'></div>
      </div>
    </button>
  );
};

export default ResultButton;
