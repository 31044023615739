import { UPDATE_LOCAL } from './modules/actionTypes';

export const updateLocal = (payload: 'korean' | 'english') => {
  return {
    type: UPDATE_LOCAL,
    payload,
  };
};

export type LocalActionType = ReturnType<typeof updateLocal>;
