import React from 'react';
import '../styles/email.form.m.style.css';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { EMAIL } from '@lib/regex';
import CBlueButton from '@components/common/CButton/CBlueButton';
import text from '@assets/text';
import useLocalize from '@hooks/components/useLocalize';

type Props = {
  name: string;
  email: string;
  isMessageShow: boolean;
  validationCheck: boolean;
  onCloseButtonClicked: () => void;
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInputError: (error: boolean) => void;
  onGetResultButtonClicked: () => void;
};

const EmailFormMobile = ({
  name,
  email,
  onCloseButtonClicked,
  isMessageShow,
  validationCheck,
  onEmailChange,
  onNameChange,
  onInputError,
  onGetResultButtonClicked,
}: Props) => {
  const { local } = useLocalize();

  return (
    <div className='email-form-m-root'>
      <section className='banner'>
        <button className='close-button' onClick={onCloseButtonClicked} />
      </section>
      <section className='content-wrapper'>
        <h1 className='title'>{text[local].email.mobile.title}</h1>
        <p className='text'>{text[local].email.mobile.para}</p>
        <div className='input-wrapper'>
          <CInputContainer
            containerStyle={{ marginTop: 66 }}
            label={text[local].email.label1}
            value={name}
            placeholer={text[local].input.placeholder2}
            onValueChange={onNameChange}
          />
          <CInputContainer
            containerStyle={{ marginTop: 50 }}
            label={text[local].email.label2}
            value={email}
            placeholer={text[local].input.placeholder3}
            isValidate={onInputError}
            onValueChange={onEmailChange}
            validation={EMAIL}
          />
          {!isMessageShow && (
            <span className='error-msg'>{text[local].email.caution}</span>
          )}
        </div>
        <CBlueButton
          disabled={!validationCheck}
          onClick={onGetResultButtonClicked}
          containerStyle={{ width: '100%', marginTop: 78, marginBottom: 60 }}>
          {text[local].button.result3}
        </CBlueButton>
      </section>
    </div>
  );
};

export default EmailFormMobile;
