import { PopupContextStore } from '@components/common/hoc/PopupProvider';
import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CopyCompletePopup from '../components/CopyCompletePopup';

type Props = {};

const CopyCompletePopupContainer = (props: Props) => {
  const navigate = useNavigate();

  const { onPopupClosed } = useContext(PopupContextStore);

  const onDiscardClicked = useCallback(() => {
    onPopupClosed();
    navigate('/');
  }, [onPopupClosed]);
  return <CopyCompletePopup onDiscardClicked={onDiscardClicked} />;
};

export default CopyCompletePopupContainer;
