import images from '@assets/images';
import text from '@assets/text';
import CBlueButton from '@components/common/CButton/CBlueButton';
import CWhiteButton from '@components/common/CButton/CWhiteButton';
import useLocalize from '@hooks/components/useLocalize';
import React from 'react';
import '../styles/quiz.start.style.css';

type Props = {
  isMoblie: boolean;
  onQuizStartClicked: () => void;
};

const QuizStart = ({ isMoblie, onQuizStartClicked }: Props) => {
  const { local } = useLocalize();

  return (
    <div className='quiz-start-root'>
      {isMoblie && (
        <img
          className='quiz-img'
          srcSet={`${images.quiz_img.quiz_img1} 400w,
          ${images.quiz_img.quiz_img2} 700w,
          ${images.quiz_img.quiz_img3} 1000w`}
          sizes='(max-width: 500px) 444px,
         (max-width: 800px) 777px,
         1222px'
          src={images.quiz_img.quiz_img1}
          alt={'img_bg'}
        />
      )}
      {!isMoblie && (
        <div className='image-container'>
          <div className='phone-img'></div>
          <div className='bg-img'></div>
        </div>
      )}

      <section className='text-section'>
        <h3 className='question'>
          {text[local].quiz.start.text1}
          {!isMoblie && <br />}
          {text[local].quiz.start.text2}
        </h3>
        <p className='answer'>
          <span className='strong'>{text[local].quiz.start.text3}</span>
          {text[local].quiz.start.text4}
        </p>
        {isMoblie ? (
          <CWhiteButton
            onClick={onQuizStartClicked}
            containerStyle={{
              padding: '0px',
              width: '100%',
              height: '60px',
              border: '3px solid #1976BA',
            }}>
            {text[local].button.start}
          </CWhiteButton>
        ) : (
          <CBlueButton
            className='start-button'
            onClick={onQuizStartClicked}
            containerStyle={{ padding: '12px 36px' }}>
            <span>{text[local].button.start + '!'}</span>
            <div className='hand' />
          </CBlueButton>
        )}
      </section>
    </div>
  );
};

export default QuizStart;
