import useQuiz from '@hooks/components/useQuiz';
import { apiRoute, requestGet, requestPost } from '@lib/api';
import { GetIPResponseType } from '@typedef/api/api.types';
import { useCallback } from 'react';

export default function useApis() {
  const { __getAnswer } = useQuiz();

  //이메일 등록하고 출시 메일받기
  const __registerEmail = useCallback(
    async (email: string, recommender?: string) => {
      const {
        data,
        config: { status, message },
      } = await requestPost<void>(
        apiRoute.email,
        {},
        {
          email: email.trim(),
          recommender,
        },
      );

      if (status !== 200) {
        console.error(message);
        return message;
      }
      return true;
    },
    [],
  );

  //퀴즈 정답 등록 및 추천인 코드 받아오기
  const __registerAnswer = useCallback(
    async (email: string, name: string, recommender?: string) => {
      const answer = await __getAnswer();

      const {
        data,
        config: { status, message },
      } = await requestPost<string>(
        apiRoute.quiz,
        {},
        {
          email: email.trim(),
          name,
          recommender,
          quiz1: answer[1],
          quiz2: answer[2],
          quiz3: answer[3],
          quiz4: answer[4],
        },
      );

      if (status !== 200) {
        console.error(message);
        return {
          result: false,
          message: message,
        };
      }
      return {
        result: true,
        message: data,
      };
    },
    [__getAnswer],
  );

  const __registerDetailInfo = useCallback(
    async (email: string, birth: string, country: string, gender: string) => {
      const {
        data,
        config: { status, message },
      } = await requestPost<void>(
        apiRoute.detail,
        {},
        { email: email.trim(), birth, country, gender },
      );

      if (status !== 200) {
        console.error(message);
        return false;
      }

      return true;
    },
    [],
  );

  //홈 화면에 param에 추천인이 있으면 사용
  const __viewRecommendedPage = useCallback(async (code: string) => {
    const {
      data,
      config: { status, message },
    } = await requestPost<void>(apiRoute.view + `?code=${code}`, {}, {});

    if (status !== 200) {
      console.error(message);
      return false;
    }
    return true;
  }, []);

  const __getIP = useCallback(async () => {
    const {
      data,
      config: { status, message },
    } = await requestGet<GetIPResponseType>(apiRoute.ip, {});
    if (status !== 200) {
      console.error(message);
      return false;
    }
    return data.country;
  }, []);

  return {
    __registerDetailInfo,
    __registerEmail,
    __registerAnswer,
    __viewRecommendedPage,
    __getIP,
  };
}
