import { PopupContextStore } from '@components/common/hoc/PopupProvider';
import useApis from '@hooks/apis/useApis';
import useRecommender from '@hooks/components/useRecommender';
import useWindowSize from '@hooks/components/useWindowSize';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Bonus from '../components/Bonus';
import BonusPopupContainer from './BonusPopupContainer';

type Props = {};

const BonusContainer = (props: Props) => {
  const { onPopupShow } = useContext(PopupContextStore);
  const { width } = useWindowSize();
  const { __registerDetailInfo } = useApis();
  const { __updateEmail, email } = useRecommender();

  const [birthList, setBirthList] = useState<string[]>([]);
  const [birth, setBirth] = useState('');
  const [country, setCountry] = useState('');
  const [gender, setGender] = useState('');

  const validation = useMemo(
    () => Boolean(birth && country && gender),
    [birth, country, gender],
  );
  const isMobile = useMemo(() => width <= 640, [width]);

  const onBirthSelected = useCallback((option: string) => {
    setBirth(option);
  }, []);

  const onBonusButtonClicked = useCallback(async () => {
    const isSuccess = await __registerDetailInfo(email, birth, country, gender);

    if (!isSuccess) {
      return;
    }

    onPopupShow(<BonusPopupContainer />);
  }, [
    BonusPopupContainer,
    onPopupShow,
    __registerDetailInfo,
    email,
    birth,
    country,
    gender,
  ]);

  const onCountrySelected = useCallback((option: string) => {
    setCountry(option);
  }, []);

  const onGenderSelected = useCallback((option: string) => {
    setGender(option);
  }, []);

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    let yearList: string[] = [];
    for (let i = year; i > year - 100; i--) {
      yearList.push(i.toString());
    }
    setBirthList(yearList);
  }, []);

  useEffect(() => {
    if (!(window as any).Kakao.isInitialized()) {
      (window as any).Kakao.init('e1922c7eafcc304a70d62ab3ffc1dfb1');
    }

    return () => {};
  }, []);

  return (
    <Bonus
      birthList={birthList}
      validation={validation}
      isMobile={isMobile}
      width={width}
      onBirthSelected={onBirthSelected}
      onBonusButtonClicked={onBonusButtonClicked}
      onCountrySelected={onCountrySelected}
      onGenderSelected={onGenderSelected}
    />
  );
};

export default BonusContainer;
