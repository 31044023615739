import DefaultPopupContainer from '@components/common/DefaultPopup/containers/DefaultPopupContainer';
import useApis from '@hooks/apis/useApis';
import usePopup from '@hooks/components/usePopup';
import useRecommender from '@hooks/components/useRecommender';
import useWindowSize from '@hooks/components/useWindowSize';
import { EMAIL } from '@lib/regex';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Home from '../Home';
import HomeMobile from '../HomeMobile';
import AlertPopupContainer from './AlertPopupContainer';
import { useLocation } from 'react-router-dom';
import useLocalize from '@hooks/components/useLocalize';

const HomeContainer = () => {
  const location = useLocation();

  const { __getIP } = useApis();
  const { local, __updateLocal } = useLocalize();
  const { __showPopup } = usePopup();
  const { width } = useWindowSize();
  const { __registerEmail, __viewRecommendedPage } = useApis();
  const { __updateRecommender, recommender } = useRecommender();

  const [email, setEmail] = useState('');
  const [paraTrigger, setParaTrigger] = useState(false);
  const [emailTrigger, setEmailTrigger] = useState(false);
  const [isCaution, setIsCaution] = useState(false);

  const validateEmail = useMemo(() => EMAIL.test(email), [email]);
  const isMobile = useMemo(() => width <= 640, [width]);

  const onEmailChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    [],
  );

  const onAlertCaution = useCallback(() => {
    if (isCaution) {
      return;
    }
    setIsCaution(true);
    setTimeout(() => {
      setIsCaution(false);
    }, 1000);
  }, [isCaution]);

  const onEmailSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!validateEmail) {
        onAlertCaution();
        return;
      }
      const result = await __registerEmail(email, recommender);
      if (typeof result === 'string') {
        __showPopup(<DefaultPopupContainer message={result} />);
        return;
      }
      __showPopup(<AlertPopupContainer />);
    },
    [
      email,
      validateEmail,
      onAlertCaution,
      __registerEmail,
      recommender,
      DefaultPopupContainer,
    ],
  );

  const onTitleAnimationEnd = useCallback(() => {
    // setParaTrigger(true);
  }, []);

  const onParaAnimationEnd = useCallback(() => {
    // setEmailTrigger(true);
  }, []);

  const onSnsClicked = useCallback((type: 'twitter' | 'insta' | 'tictok') => {
    switch (type) {
      case 'tictok':
        window.open('https://www.tiktok.com/@iyagiapp?_t=8XzvQZWE92n&_r=1');
        return;
      case 'insta':
        window.open('https://instagram.com/iyagiapp_?igshid=YTY2NzY3YTc=');
        return;
      case 'twitter':
        window.open(
          'https://twitter.com/iyagi_help/status/1582694213116125184?s=46&t=jgp9T67R4TWYz8GoDekEXw',
        );
        return;
      default:
        return;
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setParaTrigger(true);
    }, 300);
    setTimeout(() => {
      setEmailTrigger(true);
    }, 600);
  }, []);

  useEffect(() => {
    console.log(location);
    if (location && location.search !== '' && __updateRecommender) {
      __updateRecommender(
        location.search.split('?')[1].split('=')[1].split('&')[0],
      );
    }

    return () => {};
  }, [location, __updateRecommender]);

  useEffect(() => {
    if (recommender) {
      __viewRecommendedPage(recommender);
    }

    return () => {};
  }, [recommender]);

  useEffect(() => {
    __getIP().then((result) => {
      console.log(result);
      if (result == 'KR') {
        __updateLocal('korean');
      } else {
        __updateLocal('english');
      }
    });
  }, []);

  if (isMobile) {
    return (
      <HomeMobile
        local={local}
        onSnsClicked={onSnsClicked}
        onEmailSubmit={onEmailSubmit}
        email={email}
        onEmailChanged={onEmailChanged}
      />
    );
  } else {
    return (
      <Home
        local={local}
        isCaution={isCaution}
        onEmailSubmit={onEmailSubmit}
        email={email}
        onEmailChanged={onEmailChanged}
        paraAnimationTrigger={paraTrigger}
        emailAnimationTrigger={emailTrigger}
        onTitleAnimationEnd={onTitleAnimationEnd}
        onParaAnimationEnd={onParaAnimationEnd}
      />
    );
  }
};

export default HomeContainer;
