import text from '@assets/text';
import CWhiteButton from '@components/common/CButton/CWhiteButton';
import useLocalize from '@hooks/components/useLocalize';
import React from 'react';
import '../styles/alert.style.css';

type Props = {
  isMobile: boolean;
  onDiscardClicked: () => void;
};

const EmailPopup = ({ isMobile, onDiscardClicked }: Props) => {
  const { local } = useLocalize();

  return (
    <div className='alert-popup-root'>
      {isMobile && (
        <h1 className='coming-soon'>{text[local].email.popup.text1}</h1>
      )}
      <p className='text' style={{ textAlign: isMobile ? 'left' : 'center' }}>
        {text[local].email.popup.text2}
        {!isMobile && <br />}
        {text[local].email.popup.text3}
      </p>
      <CWhiteButton onClick={onDiscardClicked} className={'discard-button'}>
        {text[local].button.close}
      </CWhiteButton>
    </div>
  );
};

export default EmailPopup;
