import BonusButton from '@components/common/CButton/BonusButton';
import CBlueButton from '@components/common/CButton/CBlueButton';
import CWhiteButton from '@components/common/CButton/CWhiteButton';
import NextButton from '@components/common/CButton/NextButton';
import ResultButton from '@components/common/CButton/ResultButton';
import CCheckboxContainer from '@components/common/CCheckbox/containers/CCheckboxContainer';
import CDropdownContainer from '@components/common/CDropdown/container/CDropdownContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { EMAIL } from '@lib/regex';
import React, { useCallback, useEffect, useState } from 'react';
import './test.styles.css';

type Props = {};

const checkList = [
  'Like/Comment/Share others’ posts',
  'Enjoy the post but do nothing',
  'Mostly comment on “What you don’t like”',
  'DM a person about their post',
];

const Test = (props: Props) => {
  return <div className='test-root'></div>;
};

export default Test;
