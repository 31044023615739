import usePopup from '@hooks/components/usePopup';
import React, { useCallback } from 'react';
import DefaultPopup from '../DefaultPopup';

type Props = { message: string };

const DefaultPopupContainer = ({ message }: Props) => {
  const { __hidePopup } = usePopup();

  const onDiscardClicked = useCallback(() => {
    __hidePopup();
  }, [__hidePopup]);

  return <DefaultPopup message={message} onDiscardClicked={onDiscardClicked} />;
};

export default DefaultPopupContainer;
