import usePopup from '@hooks/components/usePopup';
import useWindowSize from '@hooks/components/useWindowSize';
import RootNavigation from '@routes/RootNavigation';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const RootNavigationContainer = () => {
  const { popup, __hidePopup } = usePopup();
  const { width } = useWindowSize();

  const [translateY, setTranslateY] = useState(80);

  const isMobileSize = useMemo(() => width < 640, [width]);

  const onBackdropClicked = useCallback(() => {
    __hidePopup();
  }, [__hidePopup]);

  useEffect(() => {
    if (popup) {
      setTimeout(() => setTranslateY(0), 10);
    } else {
      setTranslateY(80);
    }
  }, [popup]);

  return (
    <RootNavigation
      isMobileSize={isMobileSize}
      popup={popup}
      translateY={translateY}
      onBackdropClicked={onBackdropClicked}
    />
  );
};

export default RootNavigationContainer;
