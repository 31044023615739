import usePopup from '@hooks/components/usePopup';
import useWindowSize from '@hooks/components/useWindowSize';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertPopup from '../components/AlertPopup';
import GotoQuizPopupContainer from './GotoQuizPopupContainer';

type Props = {};

const AlertPopupContainer = ({}: Props) => {
  const { __showPopup, __hidePopup } = usePopup();
  const { width } = useWindowSize();

  const isMobile = useMemo(() => width <= 640, [width]);

  const onDiscardClicked = useCallback(() => {
    if (isMobile) {
      __hidePopup();
      return;
    }
    __showPopup(<GotoQuizPopupContainer />);
  }, [__showPopup, __hidePopup, GotoQuizPopupContainer, isMobile]);

  return <AlertPopup onDiscardClicked={onDiscardClicked} isMobile={isMobile} />;
};

export default AlertPopupContainer;
