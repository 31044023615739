import { PREV_STEP, NEXT_STEP, UPDATE_STEP } from './modules/actionType';

export const updateStep = (step: number) => {
  return {
    type: UPDATE_STEP,
    step,
  };
};

export const prevStep = () => {
  return {
    type: PREV_STEP,
  };
};

export const nextStep = () => {
  return {
    type: NEXT_STEP,
  };
};

export type QuizActionType =
  | ReturnType<typeof updateStep>
  | ReturnType<typeof nextStep>
  | ReturnType<typeof prevStep>;
