import images from '@assets/images';
import text from '@assets/text';
import CBlueButton from '@components/common/CButton/CBlueButton';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import QuizStartContainer from '@components/Quiz/containers/QuizStartContainer';
import { EMAIL } from '@lib/regex';
import React from 'react';
import './styles/home.mobile.style.css';

type Props = {
  local: 'korean' | 'english';
  onSnsClicked: (type: 'twitter' | 'insta' | 'tictok') => void;
  onEmailSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  email: string;
  onEmailChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const HomeMobile = ({
  local,
  onSnsClicked,
  onEmailSubmit,
  email,
  onEmailChanged,
}: Props) => {
  return (
    <main className='mobile-home-root'>
      <section>
        <img
          className='header-bg'
          srcSet={`${images.mobile_bg.mobile_bg1} 400w,
          ${images.mobile_bg.mobile_bg2} 700w,
          ${images.mobile_bg.mobile_bg3} 1000w`}
          sizes='(max-width: 500px) 444px,
         (max-width: 800px) 777px,
         1222px'
          src={images.mobile_bg.mobile_bg1}
          alt={'img_bg'}
        />
        <h1 className='title'>{text[local].home.mobile.title}</h1>
        <h2 className='subtitle'>
          {text[local].home.mobile.subtitle1}
          <br></br>
          {text[local].home.mobile.subtitle2}
        </h2>
        <p className='content'>{text[local].home.mobile.para1}</p>
        <div className='img-container'>
          <img
            className='splash-screen'
            srcSet={`${images.splash_scr.splash_scr1} 400w,
          ${images.splash_scr.splash_scr2} 700w,
          ${images.splash_scr.splash_scr3} 1000w`}
            sizes='(max-width: 500px) 444px,
         (max-width: 800px) 777px,
         1222px'
            src={images.splash_scr.splash_scr1}
            alt={'img_bg'}
          />
          <div className='wave'></div>
          <div className='cover'></div>
        </div>
      </section>
      <section>
        <h2 className='subtitle'>
          {text[local].home.mobile.subtitle3} <br></br>
          <p className='colored'>{text[local].home.mobile.subtitle4}</p>
        </h2>
        <p className='content'>{text[local].home.mobile.para2}</p>
        <div className='app-screen-container'>
          <img
            className='home-screen'
            srcSet={`${images.home_scr.home_scr1} 400w,
          ${images.home_scr.home_scr2} 700w,
          ${images.home_scr.home_scr3} 1000w`}
            sizes='(max-width: 500px) 444px,
         (max-width: 800px) 777px,
         1222px'
            src={images.home_scr.home_scr1}
            alt={'img_bg'}
          />
          <img
            className='post-screen'
            srcSet={`${images.post_scr.post_scr1} 400w,
          ${images.post_scr.post_scr2} 700w,
          ${images.post_scr.post_scr3} 1000w`}
            sizes='(max-width: 500px) 444px,
         (max-width: 800px) 777px,
         1222px'
            src={images.post_scr.post_scr1}
            alt={'img_bg'}
          />
        </div>
      </section>
      <section>
        <h2 className='subtitle'>
          <span className='colored'>{text[local].home.mobile.subtitle5}</span>
          {text[local].home.mobile.subtitle6}
        </h2>
        <p className='content'>
          {text[local].home.mobile.para3} <br />
          {text[local].home.mobile.para4}
        </p>
        <form className='email-form' onSubmit={onEmailSubmit}>
          <CInputContainer
            value={email}
            containerStyle={{ width: '100%', marginTop: 48 }}
            inputStyle={{ width: '100%' }}
            placeholer='enteryourmail@mail.com'
            onValueChange={onEmailChanged}
            validation={EMAIL}
          />
          <CBlueButton
            containerStyle={{
              boxShadow: '3px 3px 3px #D0D0D0',
              marginTop: 24,
            }}>
            {text[local].button.notify2}
          </CBlueButton>
        </form>
      </section>
      <section>
        <h2 className='section-title'>Take a quiz</h2>
        <div className='divider' />
        <QuizStartContainer />
      </section>
      <section>
        <h2 className='section-title'>Contact us</h2>
        <div className='divider' />
        <div className='button-wrapper'>
          <button
            className='img-button'
            onClick={() => onSnsClicked('insta')}
          />
          <button
            className='img-button'
            onClick={() => onSnsClicked('twitter')}
          />
          <button
            className='img-button'
            onClick={() => onSnsClicked('tictok')}
          />
          <a className='img-button' href='mailto:communicia@communicia.com' />
        </div>
      </section>
    </main>
  );
};

export default HomeMobile;
