import usePopup from '@hooks/components/usePopup';
import useQuiz from '@hooks/components/useQuiz';
import useWindowSize from '@hooks/components/useWindowSize';
import React, { useCallback, useMemo } from 'react';
import EmailPopup from '../components/EmailPopup';

type Props = {};

const EmailPopupContainer = (props: Props) => {
  const { __hidePopup } = usePopup();
  const { width } = useWindowSize();
  const { __toNextStep, __initializeStep, __initializeAnswer } = useQuiz();

  const isMobile = useMemo(() => width <= 640, [width]);

  const onDiscardClicked = useCallback(() => {
    //TODO: 퀴즈 답변 전송
    __initializeStep();
    __initializeAnswer();
    __toNextStep();
    __hidePopup();
  }, [__hidePopup, __toNextStep, __initializeStep]);

  return <EmailPopup onDiscardClicked={onDiscardClicked} isMobile={isMobile} />;
};

export default EmailPopupContainer;
