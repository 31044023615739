import text from '@assets/text';
import CWhiteButton from '@components/common/CButton/CWhiteButton';
import useLocalize from '@hooks/components/useLocalize';
import React from 'react';
import '../styles/alert.style.css';

type Props = { isMobile: boolean; onDiscardClicked: () => void };

const AlertPopup = ({ onDiscardClicked, isMobile }: Props) => {
  const { local } = useLocalize();

  return (
    <div className='alert-popup-root'>
      {isMobile && <h1 className='coming-soon'>{text[local].popup.text1}</h1>}
      {!isMobile && (
        <p className='text'>
          {text[local].popup.text2}
          <br />
          {text[local].popup.text3}
        </p>
      )}
      {isMobile && <p className='text'>{text[local].popup.text4}</p>}
      <CWhiteButton onClick={onDiscardClicked} className={'discard-button'}>
        {text[local].button.close}
      </CWhiteButton>
    </div>
  );
};

export default AlertPopup;
