import { HeaderActionType } from './actions';
import { TURN_OFF_NEON, TURN_ON_NEON } from './modules/actionType';

const initialState: HeaderStateType = {
  neon: false,
};

export type HeaderStateType = {
  neon: boolean;
};

export default function reducer(
  state = initialState,
  { type }: HeaderActionType,
): HeaderStateType {
  switch (type) {
    case TURN_ON_NEON:
      return {
        neon: true,
      };
    case TURN_OFF_NEON:
      return {
        neon: false,
      };
    default:
      return state;
  }
}
