import React, { useEffect, useMemo, useState } from 'react';
import CInput from '../CInput';

type Props = {
  label?: string;
  value: string;
  placeholer?: string;
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  validation?: RegExp;
  isValidate?: (correct: boolean) => void;
};

const CInputContainer = ({
  label,
  value,
  validation,
  placeholer,
  isValidate,
  onValueChange,
  onBlur,
  inputStyle,
  containerStyle,
}: Props) => {
  const [delayedState, setDelayedState] = useState(true);
  const [isVibrate, setIsVibrate] = useState(false);
  const isCorrect = useMemo(() => {
    if (!value) {
      return true;
    }
    return validation?.test(value) ?? true;
  }, [validation, value]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setDelayedState(isCorrect);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, isCorrect]);

  useEffect(() => {
    if (!delayedState) {
      if (isValidate) {
        isValidate(false);
      }
      setIsVibrate(true);
      setTimeout(() => {
        setIsVibrate(false);
      }, 400);
    } else {
      if (isValidate) {
        isValidate(true);
      }
    }
  }, [delayedState]);

  return (
    <CInput
      isVibrate={isVibrate}
      isCorrect={delayedState}
      placeholder={placeholer}
      inputStyle={inputStyle}
      containerStyle={containerStyle}
      label={label}
      value={value}
      onValueChange={onValueChange}
      onBlur={onBlur}
    />
  );
};

export default CInputContainer;
