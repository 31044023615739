import useQuiz from '@hooks/components/useQuiz';
import useWindowSize from '@hooks/components/useWindowSize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import QuizStep4 from '../components/QuizStep4';

type Props = {};

const QuizStep4Container = (props: Props) => {
  const { __getAnswer, __toNextStep, __storeAnswer } = useQuiz();
  const { width } = useWindowSize();

  const [checkedNumber, setCheckedNumber] = useState(-1);

  const isMobile = useMemo(() => width <= 640, [width]);

  const onCheckClicked = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedNumber(parseInt(e.target.id));
    },
    [__toNextStep],
  );

  const onResultButtonClicked = useCallback(() => {
    __storeAnswer(4, checkedNumber);
    __toNextStep();
  }, [__storeAnswer, checkedNumber]);

  useEffect(() => {
    const answer = __getAnswer();
    setCheckedNumber(answer[4] || -1);
  }, []);

  return (
    <QuizStep4
      checkedNumber={checkedNumber}
      isMobile={isMobile}
      onCheckClicked={onCheckClicked}
      onResultButtonClicked={onResultButtonClicked}
    />
  );
};

export default QuizStep4Container;
