import useHeader from '@hooks/components/useHeader';
import usePopup from '@hooks/components/usePopup';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import QuizPopup from '../components/QuizPopup';

type Props = {};

const GotoQuizPopupContainer = ({}: Props) => {
  const navigate = useNavigate();

  const { __hidePopup } = usePopup();
  const { __turnOffNeon, __turnOnNeon } = useHeader();

  const onDiscardClicked = useCallback(() => {
    __hidePopup();
    navigate('quiz');
  }, [navigate, __hidePopup]);

  useEffect(() => {
    __turnOnNeon();
    return () => {
      __turnOffNeon();
    };
  }, []);

  return <QuizPopup onDiscardClicked={onDiscardClicked} />;
};

export default GotoQuizPopupContainer;
