import GotoQuizPopupContainer from '@components/Home/containers/GotoQuizPopupContainer';
import useHeader from '@hooks/components/useHeader';
import usePopup from '@hooks/components/usePopup';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import CHeader from '../CHeader';

type Props = {};

const CHeaderContainer = (props: Props) => {
  const navigate = useNavigate();

  const { neon } = useHeader();

  const onContactUsClicked = useCallback(() => {}, []);

  const onTakeQuizClicked = useCallback(() => {
    navigate('quiz');
  }, []);

  const onSnsClicked = useCallback((type: 'twitter' | 'insta' | 'tictok') => {
    switch (type) {
      case 'tictok':
        window.open('https://www.tiktok.com/@iyagiapp?_t=8XzvQZWE92n&_r=1');
        return;
      case 'insta':
        window.open('https://instagram.com/iyagiapp_?igshid=YTY2NzY3YTc=');
        return;
      case 'twitter':
        window.open(
          'https://twitter.com/iyagi_help/status/1582694213116125184?s=46&t=jgp9T67R4TWYz8GoDekEXw',
        );
        return;
      default:
        return;
    }
  }, []);

  return (
    <CHeader
      neon={neon}
      onContactUsClicked={onContactUsClicked}
      onSnsClicked={onSnsClicked}
      onTakeQuizClicked={onTakeQuizClicked}
    />
  );
};

export default CHeaderContainer;
