import text from '@assets/text';
import CWhiteButton from '@components/common/CButton/CWhiteButton';
import useLocalize from '@hooks/components/useLocalize';
import React from 'react';
import '../styles/alert.style.css';

type Props = { onDiscardClicked: () => void };

const CopyCompletePopup = ({ onDiscardClicked }: Props) => {
  const { local } = useLocalize();

  return (
    <div className='alert-popup-root' style={{ width: 827 }}>
      <div className='smile-img' />
      <h1 className='link-copy'>{text[local].popup.text7}</h1>
      <p className='text' style={{ textAlign: 'justify' }}>
        {text[local].popup.text8}
      </p>
      <CWhiteButton onClick={onDiscardClicked} className={'discard-button'}>
        {text[local].button.close}
      </CWhiteButton>
    </div>
  );
};

export default CopyCompletePopup;
