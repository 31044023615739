const images = {
  instagram: {
    default: '/assets/images/icon/instagram-default@3x.png',
    hover: '/assets/images/icon/instagram-hover@3x.png',
    click: '/assets/images/icon/instagram-click@3x.png',
  },
  tictok: {
    default: '/assets/images/icon/tictok-default@3x.png',
    hover: '/assets/images/icon/tictok-hover@3x.png',
    click: '/assets/images/icon/tictok-click@3x.png',
  },
  twitter: {
    default: '/assets/images/icon/twitter-default@3x.png',
    hover: '/assets/images/icon/twitter-hover@3x.png',
    click: '/assets/images/icon/twitter-click@3x.png',
  },
  image: {
    phone1: '/assets/images/image/app-1@3x.png',
    phone2: '/assets/images/image/app-2@3x.png',
    phone3: '/assets/images/image/app-3@3x.png',
  },
  caution: {
    caution1: '/assets/images/icon/caution.png',
    caution2: '/assets/images/icon/caution@2x.png',
    caution3: '/assets/images/icon/caution@3x.png',
  },
  home_scr: {
    home_scr1: '/assets/images/image/home-screen.png',
    home_scr2: '/assets/images/image/home-screen@2x.png',
    home_scr3: '/assets/images/image/home-screen@3x.png',
  },
  post_scr: {
    post_scr1: '/assets/images/image/post-screen.png',
    post_scr2: '/assets/images/image/post-screen@2x.png',
    post_scr3: '/assets/images/image/post-screen@3x.png',
  },
  splash_scr: {
    splash_scr1: '/assets/images/image/post-screen.png',
    splash_scr2: '/assets/images/image/splash-screen@2x.png',
    splash_scr3: '/assets/images/image/splash-screen@3x.png',
  },
  quiz_img: {
    quiz_img1: '/assets/images/image/quiz-image.png',
    quiz_img2: '/assets/images/image/quiz-image@2x.png',
    quiz_img3: '/assets/images/image/quiz-image@3x.png',
  },
  mobile_bg: {
    mobile_bg1: '/assets/images/image/bg-m.png',
    mobile_bg2: '/assets/images/image/bg-m@2x.png',
    mobile_bg3: '/assets/images/image/bg-m@3x.png',
  },
  hand: {
    hand1: '/assets/images/icon/hand.png',
    hand2: '/assets/images/icon/hand@2x.png',
    hand3: '/assets/images/icon/hand@3x.png',
  },
};

export default images;
