import text from '@assets/text';
import NextButton from '@components/common/CButton/NextButton';
import CCheckboxContainer from '@components/common/CCheckbox/containers/CCheckboxContainer';
import useLocalize from '@hooks/components/useLocalize';
import useWindowSize from '@hooks/components/useWindowSize';
import React from 'react';
import '../styles/quiz.question.style.css';

type Props = {
  checkedNumber: number;
  isMobile: boolean;
  onCheckClicked: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNextButtonClicked: () => void;
};

const QuizStep1 = ({
  checkedNumber,
  isMobile,
  onCheckClicked,
  onNextButtonClicked,
}: Props) => {
  const { width } = useWindowSize();
  const { local } = useLocalize();

  return (
    <div
      className='quiz-question-root'
      style={{ gap: width <= 1300 ? 32 : 118 }}>
      <section>
        <div className='question'>
          {text[local].quiz.step1.text1}
          {isMobile && <br />} {text[local].quiz.step1.text2}
          {!isMobile && <br />}
          {text[local].quiz.step1.text3}
        </div>
        <div className='checkbox-wrapper'>
          {text[local].quiz.step1.checkList.map((value, index) => (
            <CCheckboxContainer
              key={index}
              checked={checkedNumber === index}
              id={JSON.stringify(index)}
              name='check'
              onCheckClicked={onCheckClicked}
              label={value}
            />
          ))}
        </div>
      </section>
      <section className='next-button-wrapper'>
        <NextButton
          disabled={checkedNumber === -1}
          onClick={onNextButtonClicked}
        />
      </section>
    </div>
  );
};

export default QuizStep1;
