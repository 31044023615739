import text from '@assets/text';
import useLocalize from '@hooks/components/useLocalize';
import React from 'react';
import './styles/nextbutton.style.css';

type Props = {
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const NextButton = ({
  containerStyle,
  className,
  onClick,
  disabled = false,
}: Props) => {
  const { local } = useLocalize();
  return (
    <button
      className={`nextbutton-root ${className}`}
      style={containerStyle}
      onClick={onClick}
      disabled={disabled}>
      <div className='rect'>
        <span className='text'>{text[local].button.next}</span>
        <div className='hand'></div>
      </div>
    </button>
  );
};

export default NextButton;
