const text = {
  english: {
    button: {
      bonus: 'Share and Get My Bonus!',
      next: 'Go Next',
      result1: 'Check',
      result2: 'The Result',
      close: 'Close',
      quiz: 'Go to Quiz',
      notify: 'Get Notified!',
      notify2: 'GET NOTIFIED',
      copy: 'Copy',
      result3: 'Get My Result!',
      start: 'Let’s Get Started',
    },
    header: {
      quiz: 'Take a quiz',
      contact: 'Contact us',
    },
    popup: {
      text1: 'Thank you !',
      text2: 'Thank you for your interest in IYAGI.',
      text3: 'Please, check your inbox',
      text4: 'Thank you for your interest in IYAGI. Please, check your inbox',
      text5: 'Take a quick quiz and ',
      text6: 'find out what type of social media user you are. ',
      text7: 'An amazing bonus is waiting for you as well!',
      text8: 'Link copied successfully!',
      text9: 'Check your inbox. You will receive your BONUS shortly!',
    },
    input: {
      placeholder1: 'Enter your email address',
      placeholder2: 'Name',
      placeholder3: 'Email_address@email.com',
    },
    home: {
      title: 'COMING SOON',
      subtitle: 'NEW social media app',
      para1: `IYAGI is here to create a revolution in the way we communicate online. 
            Our goal is to offer you the chance to show who you truly are by caring 
            more about your opinions, your beliefs, your everyday tales and less 
            about what you look like, what’s in your wardrobe, or the last vacation you went to. `,
      para2: `With IYAGI, you can express yourself freely, and connect to people you
vibe with. You are unique, and now, you can show it to the world.`,
      caution: 'Please enter your email address',
      mobile: {
        title: 'IYAGI',
        subtitle1: 'NEW social media app',
        subtitle2: 'Coming SOON',
        para1:
          'IYAGI is here to create a revolution in the way we communicate online.',
        subtitle3: 'Our goal is to offer you the chance to show',
        subtitle4: 'who you truly are',
        para2: `by caring more about your opinions, your beliefs, your everyday tales
          and less about what you look like, what’s in your wardrobe, or the
          last vacation you went to.`,
        subtitle5: 'With IYAGI,',
        subtitle6: `you can express yourself
          freely, and connect to people you vibe with!`,
        para3: 'You are unique, and now,',
        para4: 'you can show it to the world!',
      },
    },
    bonus: {
      title1: 'Recommend this quiz ',
      title2: 'to your friends and get',
      title3: 'a special BONUS!',
      para1:
        'Please enter your info and share the link with your friends. A special bonus is waiting for you!',
      label1: 'Year of birth',
      label2: 'Country',
      label3: 'Gender',
      options: ['Female', 'Male', 'Custom', 'Not specified'],
    },
    email: {
      title1: 'Thank you for participating',
      title2: 'in our quiz!',
      para1: 'To discover who you are as a Social Media user, ',
      para2: 'please enter your name and your email, and',
      para3: 'you will receive the results right away and for free!',
      label1: 'Your name',
      label2: 'Your email',
      caution: 'Email address is invalid.',
      mobile: {
        title: 'Thank you for participating in our quiz!',
        para: `To discover who you are as a Social Media user, please enter your name
          and your email, and you will receive the results right away and for
          free!`,
      },
      popup: {
        text1: 'The result will be sent',
        text2: 'The result will be sent to your inbox soon.',
        text3: 'Thank you for participating :)',
      },
    },
    quiz: {
      start: {
        text1: 'Curious about what type',
        text2: 'of Social Media User you are?',
        text3: 'Find out for FREE by answering a few questions. ',
        text4:
          'This will help you understand your behavior and how others perceive you on Social Media.',
      },
      step1: {
        text1: 'When using',
        text2: 'social media,',
        text3: 'do you:',
        checkList: [
          'Like/Comment/Share others’ posts',
          'Enjoy the post but do nothing',
          'Mostly comment on “What you don’t like”',
          'DM a person about their post',
        ],
      },
      step2: {
        text1: 'What do you ',
        text2: 'use Social Media',
        text3: ' for:',
        checkList: [
          'Meet new people',
          'Build a reputation online',
          'Staying up-to-date with the latest news/trends',
          'Laughing at others',
        ],
      },
      step3: {
        text1: 'On Social Media,',
        text2: 'do other people:',
        checkList: [
          'Tag you on their posts',
          'Block you from their accounts',
          'Share your posts and stories',
          'Send you DMs to be a “brand ambassador”',
        ],
      },
      step4: {
        text1: 'How frequently',
        text2: 'do you post',
        text3: 'on Social Media:',
        checkList: [
          'Everyday',
          'Few times during the month',
          'Whenever you feel like it',
          'Hardly ever',
        ],
      },
    },
  },
  korean: {
    button: {
      bonus: '친구들과 공유하고 보너스 받기!',
      next: '다음',
      result1: '결과',
      result2: ' 보기',
      close: '닫기',
      quiz: '퀴즈풀러가기',
      notify: '알림받기!',
      notify2: '알림받기',
      copy: '복사',
      result3: '결과 받아 보기!',
      start: '시작하기!',
    },
    header: {
      quiz: 'Take a quiz',
      contact: 'Contact us',
    },
    popup: {
      text1: '감사합니다 !',
      text2: 'IYAGI에 관심을 가져주셔서 감사합니다.',
      text3: '메일함을 확인해주세요',
      text4: 'IYAGI에 관심을 가져주셔서 감사합니다. 메일함을 확인해주세요',
      text5: '간단한 퀴즈를 풀고',
      text6: '여러분의 SNS스타일을 확인하세요.',
      text7: '보너스가 여러분을 기다리고 있습니다',
      text8: '링크가 성공적으로 복사되었습니다!',
      text9: '메일함을 확인해주세요. 곧 보너스가 도착합니다!',
    },
    input: {
      placeholder1: '이메일을 입력해주세요',
      placeholder2: '이름',
      placeholder3: 'Email_address@email.com',
    },
    home: {
      title: 'COMING SOON',
      subtitle: 'NEW social media app',
      para1: `IYAGI는 새로운 방식의 소통을 담은 모바일 앱입니다.
우리의 목표는 스스럼없이 자신을 드러낼 수 있는 세상을 만드는 것입니다.
당신의 의견, 당신의 신념, 당신의 일상적인 이야기 등에 대해
당신이 어떻게 생겼는지, 옷장에 무엇이 있는지, 또는 당신이 마지막으로 갔던 휴가에 대해 알려주세요.`,
      para2: `IYAGI가 만드는 자유로운 세상을 마주하세요`,
      caution: '정확한 이메일을 입력해주세요',
      mobile: {
        title: 'IYAGI',
        subtitle1: 'NEW social media app',
        subtitle2: 'Coming SOON',
        para1: 'IYAGI는 새로운 방식의 소통을 담은 모바일 앱입니다.',
        subtitle3: '우리의 목표는 스스로를 드러낼 수 있게끔 하는 것입니다.',
        subtitle4: '당신이 누구인지',
        para2: `당신의 의견, 당신의 신념, 당신의 일상적인 이야기 등에 대해
당신이 어떻게 생겼는지, 옷장에 무엇이 있는지, 또는 당신이 마지막으로 갔던 휴가에 대해.`,
        subtitle5: 'IYAGI로 ',
        subtitle6: `자유롭게 표현하고 또 여러 사람과 만나세요`,
        para3: '당신의 특별함을',
        para4: '세상에 보여주세요',
      },
    },
    bonus: {
      title1: '친구들에게 ',
      title2: '퀴즈를 추천하고 ',
      title3: '보너스를 가지세요',
      para1:
        '정보를 입력하고 친구와 링크를 공유하십시오. 특별 보너스가 당신을 기다리고 있습니다!',
      label1: '생년월일',
      label2: '국가',
      label3: '성별',
      options: ['여성', '남성', '맞춤', '미정'],
    },
    email: {
      title1: '퀴즈에 참여해주셔서',
      title2: '감사합니다!',
      para1: '이름과 이메일을 알려주시면',
      para2: '당신의 SNS 사용 스타일 결과를 ',
      para3: '바로 무료로 받아보실 수 있습니다!',
      label1: '이름',
      label2: '이메일',
      caution: '정확한 이메일을 입력해주세요',
      mobile: {
        title: '퀴즈에 참여해주셔서 감사합니다!',
        para: `이름과 이메일을 알려주시면 당신의 SNS 사용 스타일 결과를 바로 받아보실 수 있습니다!`,
      },
      popup: {
        text1: '이메일이 곧 도착합니다',
        text2: '이메일이 곧 도착합니다',
        text3: '참여해주셔서 감사합니다 :)',
      },
    },
    quiz: {
      start: {
        text1: '당신의 소셜미디어 유형이',
        text2: '궁금하신가요?',
        text3: '저희가 준비한 퀴즈를 풀면 알 수 있습니다. ',
        text4:
          '당신의 행동과 다른 사람들이 소셜 미디어에서 당신을 어떻게 인식하는지 이해하는 데 도움이 될 것입니다.',
      },
      step1: {
        text1: 'SNS를',
        text2: '사용할 때,',
        text3: '당신은:',
        checkList: [
          '다른사람의 게시물에 좋아요, 댓글 혹은 공유을 합니다',
          '다른사람의 게시물을 보기만 합니다',
          '대개 맘에 들지 않는 게시물에만 댓글을 답니다',
          '게시물의 주인에게 DM을 보냅니다',
        ],
      },
      step2: {
        text1: '당신이',
        text2: 'SNS를 사용하는',
        text3: '목적은:',
        checkList: [
          '새로운 사람들을 만나기 위해서',
          '온라인 상에서 유명해지기 위해서',
          '최신 트렌드에 익숙해지기 위해서',
          '다른사람들을 조롱하기 위해서',
        ],
      },
      step3: {
        text1: 'SNS에서 ',
        text2: '다른사람들은:',
        checkList: [
          '당신을 태그합니다',
          '당신을 차단합니다',
          '당신의 게시물, 스토리를 공유합니다',
          '당신에게 "브랜드 홍보대사"를 권유합니다',
        ],
      },
      step4: {
        text1: '얼마나 자주',
        text2: 'SNS 상에',
        text3: '게시물을 올리나요:',
        checkList: ['매일', '한달에 몇번정도', '내킬때', '거의 안함'],
      },
    },
  },
};

export default text;
