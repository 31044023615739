import text from '@assets/text';
import useLocalize from '@hooks/components/useLocalize';
import React from 'react';
import CWhiteButton from '../CButton/CWhiteButton';
import './styles/default.popup.style.css';

type Props = { message: string; onDiscardClicked: () => void };

const DefaultPopup = ({ message, onDiscardClicked }: Props) => {
  const { local } = useLocalize();
  return (
    <div className='default-popup-root'>
      <p className='text'>{message}</p>
      <CWhiteButton onClick={onDiscardClicked} className={'discard-button'}>
        {text[local].button.close}
      </CWhiteButton>
    </div>
  );
};

export default DefaultPopup;
