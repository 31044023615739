import React, { useCallback, useState } from 'react';
import CDropdown from '../CDropdown';

type Props = {
  options: string[];
  label: string;
  width: number;
  className?: string;
  containerStyle?: React.CSSProperties;
  onOptionSelected?: (option: string) => void;
};

const CDropdownContainer = ({
  label,
  options,
  width,
  className,
  containerStyle,
  onOptionSelected,
}: Props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('');

  const onDropdownClicked = useCallback(() => {
    setIsFocused((prev) => !prev);
  }, []);

  const onOptionClicked = useCallback(
    (option: string) => {
      if (onOptionSelected) {
        onOptionSelected(option);
      }
      setSelectedOption(option);
    },
    [onOptionSelected],
  );

  return (
    <CDropdown
      width={width}
      isFocused={isFocused}
      selectedOption={selectedOption}
      options={options}
      label={label}
      className={className}
      containerStyle={containerStyle}
      onDropdownClicked={onDropdownClicked}
      onOptionClicked={onOptionClicked}
    />
  );
};

export default CDropdownContainer;
