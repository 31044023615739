import React, { useEffect, useState } from 'react';
import CCheckbox from '../CCheckbox';

type Props = {
  checked: boolean;
  id: string;
  name: string;
  onCheckClicked: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  containerStyle?: React.CSSProperties;
};

const CCheckboxContainer = (props: Props) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (props.checked) {
      setWidth(1);
    } else {
      setWidth(0);
    }
  }, [props.checked]);

  return <CCheckbox {...props} width={width} />;
};

export default CCheckboxContainer;
