import React, { useEffect, useState } from 'react';

type Props = {
  trigger: boolean;
  second?: number;
  onEffectEnd?: () => void;
  children: JSX.Element | JSX.Element[];
};

const FloatUpEffect = ({
  children,
  trigger,
  onEffectEnd,
  second = 1,
}: Props) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (trigger) {
      setProgress(100);
    } else {
      setProgress(0);
    }
  }, [trigger]);

  useEffect(() => {
    if (onEffectEnd && trigger) {
      let timeout = setTimeout(() => {
        console.log('test');
        onEffectEnd();
      }, second * 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [trigger, progress, onEffectEnd]);

  return (
    <div
      className='floatup-root'
      style={{
        transition: `transform ${second}s, opacity ${second}s`,
        transform: `translateY(${100 - progress}%)`,
        opacity: `${progress / 100}`,
      }}>
      {children}
    </div>
  );
};

export default FloatUpEffect;
