import {
  UPDATE_RECOMMENDER,
  UPDATE_CODE,
  UPDATE_EMAIL,
} from './modules/actionType';

export const updateRecommender = (recommender: string) => {
  return {
    type: UPDATE_RECOMMENDER,
    payload: {
      recommender,
    },
  };
};

export const updateCode = (code: string) => {
  return {
    type: UPDATE_CODE,
    payload: {
      code,
    },
  };
};

export const updateEmail = (email: string) => {
  return {
    type: UPDATE_EMAIL,
    payload: {
      email,
    },
  };
};

export type RecommenderActionType =
  | ReturnType<typeof updateRecommender>
  | ReturnType<typeof updateCode>
  | ReturnType<typeof updateEmail>;
