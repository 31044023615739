import images from '@assets/images';
import React, { useMemo } from 'react';
import './styles/input.style.css';

type Props = {
  isVibrate: boolean;
  isCorrect: boolean;
  label?: string;
  value: string;
  placeholder?: string;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const CInput = ({
  isVibrate,
  isCorrect,
  label,
  value,
  placeholder,
  containerStyle,
  inputStyle,
  onValueChange,
  onBlur,
}: Props) => {
  const isFilled = useMemo(() => value !== '', [value]);
  return (
    <span
      className={`cinput-root ${isCorrect ? '' : 'caution'}`}
      style={containerStyle}>
      {label && <span className='label'>{label}</span>}
      <input
        className={`input ${isFilled ? 'fill' : ''}`}
        style={inputStyle}
        placeholder={placeholder}
        type='text'
        value={value}
        onChange={onValueChange}
        onBlur={onBlur}
      />
      {!isCorrect && (
        <img
          className={`img ${isVibrate ? 'vibration' : ''}`}
          srcSet={`${images.caution.caution1} 400w,
          ${images.caution.caution2} 700w,
          ${images.caution.caution3} 1000w`}
          sizes='(max-width: 500px) 444px,
         (max-width: 800px) 777px,
         1222px'
          src={images.caution.caution1}
          alt='ic_caution'
        />
      )}
    </span>
  );
};

export default CInput;
