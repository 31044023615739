import text from '@assets/text';
import CBlueButton from '@components/common/CButton/CBlueButton';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import useLocalize from '@hooks/components/useLocalize';
import { EMAIL } from '@lib/regex';
import React from 'react';
import '../styles/email.form.style.css';

type Props = {
  name: string;
  email: string;
  isMessageShow: boolean;
  validationCheck: boolean;
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInputError: (error: boolean) => void;
  onGetResultButtonClicked: () => void;
};

const EmailForm = ({
  name,
  email,
  isMessageShow,
  validationCheck,
  onInputError,
  onEmailChange,
  onNameChange,
  onGetResultButtonClicked,
}: Props) => {
  const { local } = useLocalize();

  return (
    <div className='email-form-root'>
      <section>
        <h2 className='title'>
          {text[local].email.title1}
          <br />
          {text[local].email.title2}
        </h2>
        <p className='text'>
          {text[local].email.para1}
          <br />
          {text[local].email.para2} <br />
          {text[local].email.para3}
        </p>
        <div className='input-wrapper'>
          <CInputContainer
            label={text[local].email.label1}
            value={name}
            placeholer={text[local].input.placeholder2}
            containerStyle={{ width: 208 }}
            inputStyle={{ width: '100%' }}
            onValueChange={onNameChange}
          />
          <CInputContainer
            label={text[local].email.label2}
            value={email}
            placeholer={text[local].input.placeholder3}
            isValidate={onInputError}
            onValueChange={onEmailChange}
            containerStyle={{ width: 306 }}
            inputStyle={{ width: '100%' }}
            validation={EMAIL}
          />
          {!isMessageShow && (
            <span className='message'>{text[local].email.caution}</span>
          )}
        </div>
        <CBlueButton
          disabled={!validationCheck}
          onClick={onGetResultButtonClicked}
          containerStyle={{
            width: 518,
            marginTop: 40,
            fontSize: 32,
            fontWeight: 'bold',
          }}>
          {text[local].button.result3}
        </CBlueButton>
      </section>
      <section>
        <div className='email-form-img'></div>
      </section>
    </div>
  );
};

export default EmailForm;
