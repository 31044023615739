import { nextStep, prevStep, updateStep } from '@store/quiz/actions';
import { RootState } from '@store/rootReducer';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const QUIZ_KEY = '@QUIZ_KEY';
const QUIZ_ANSWER_KEY = '@QUIZ_ANSWER_KEY';

export type AnswerType = {
  [key: number]: number;
};

export default function useQuiz() {
  //0단계: quiz start
  //모바일을 1단계부터 시작
  const dispatch = useDispatch();
  const { step } = useSelector((state: RootState) => state.quizReducer);

  const [answer, setAnswer] = useState<AnswerType>(
    JSON.parse(sessionStorage.getItem(QUIZ_ANSWER_KEY) || '{}'),
  );

  const __toNextStep = useCallback(() => {
    sessionStorage.setItem(QUIZ_KEY, JSON.stringify(step + 1));
    dispatch(nextStep());
  }, [step]);

  const __toPrevStep = useCallback(() => {
    sessionStorage.setItem(QUIZ_KEY, JSON.stringify(step - 1));
    dispatch(prevStep());
  }, [step]);

  const __initializeStep = useCallback(() => {
    sessionStorage.setItem(QUIZ_KEY, JSON.stringify(0));
    dispatch(updateStep(0));
  }, []);

  const __getAnswer = useCallback(() => {
    return JSON.parse(
      sessionStorage.getItem(QUIZ_ANSWER_KEY) || '{}',
    ) as AnswerType;
  }, []);

  const __storeAnswer = useCallback(
    (step: number, check: number) => {
      sessionStorage.setItem(
        QUIZ_ANSWER_KEY,
        JSON.stringify({ ...answer, [step]: check }),
      );
      setAnswer({ ...answer, [step]: check });
    },
    [answer],
  );

  const __initializeAnswer = useCallback(() => {
    sessionStorage.removeItem(QUIZ_ANSWER_KEY);
  }, []);

  useLayoutEffect(() => {
    dispatch(updateStep(JSON.parse(sessionStorage.getItem(QUIZ_KEY) || '0')));
  }, []);

  return {
    step,
    __initializeStep,
    __initializeAnswer,
    __getAnswer,
    __toNextStep,
    __toPrevStep,
    __storeAnswer,
  };
}
