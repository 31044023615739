import images from '../../assets/images';
import CBlueButton from '@components/common/CButton/CBlueButton';
import FloatUpEffect from '@components/common/hoc/FloatUpEffect';
import React from 'react';
import './styles/home.style.css';
import text from '@assets/text';

type Props = {
  local: 'korean' | 'english';
  isCaution: boolean;
  onEmailSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  email: string;
  onEmailChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  paraAnimationTrigger: boolean;
  emailAnimationTrigger: boolean;
  onTitleAnimationEnd: () => void;
  onParaAnimationEnd: () => void;
};

const Home = ({
  local,
  isCaution,
  onEmailSubmit,
  email,
  onEmailChanged,
  paraAnimationTrigger,
  emailAnimationTrigger,
  onTitleAnimationEnd,
  onParaAnimationEnd,
}: Props) => {
  return (
    <main className='main-root'>
      <section>
        <FloatUpEffect trigger={true} onEffectEnd={onTitleAnimationEnd}>
          <h3 className='subtitle'></h3>
          <h1 className='title'>{text[local].home.title}</h1>
        </FloatUpEffect>
        <FloatUpEffect
          trigger={paraAnimationTrigger}
          onEffectEnd={onParaAnimationEnd}>
          <div className='paragraph'>
            {text[local].home.para1}
            <br />
            {text[local].home.para2}
          </div>
        </FloatUpEffect>
        <FloatUpEffect trigger={emailAnimationTrigger}>
          <form className='email-form' onSubmit={onEmailSubmit}>
            <input
              className='email-input'
              type='text'
              value={email}
              onChange={onEmailChanged}
              placeholder={text[local].input.placeholder1}
            />
            {isCaution && (
              <span className='email-caution'>{text[local].home.caution}</span>
            )}
            <CBlueButton
              className='submit-button'
              containerStyle={{
                borderRadius: 2,
                padding: '12px 12px',
              }}>
              {text[local].button.notify}
            </CBlueButton>
          </form>
        </FloatUpEffect>
      </section>
      <FloatUpEffect trigger={true}>
        <section className='phone-img-container'>
          <img src={images.image.phone1} alt='ic_phone' />
          <img src={images.image.phone2} alt='ic_phone' />
          <img src={images.image.phone3} alt='ic_phone' />
        </section>
      </FloatUpEffect>
    </main>
  );
};

export default Home;
