import { RecommenderActionType } from './actions';
import {
  UPDATE_CODE,
  UPDATE_EMAIL,
  UPDATE_RECOMMENDER,
} from './modules/actionType';

export type RecommnederState = {
  recommender?: string;
  code: string;
  email: string;
};

const initialState: RecommnederState = {
  recommender: undefined,
  code: '',
  email: '',
};

export default function reducer(
  state = initialState,
  { type, payload }: RecommenderActionType,
): RecommnederState {
  switch (type) {
    case UPDATE_RECOMMENDER:
      return {
        ...state,
        recommender: payload.recommender,
      };
    case UPDATE_CODE:
      return {
        ...state,
        code: payload.code,
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        email: payload.email,
      };
    default:
      return state;
  }
}
