import React from 'react';
import './styles/cbutton.blue.style.css';

type Props = {
  children: JSX.Element | JSX.Element[] | string;
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const CBlueButton = ({
  children,
  containerStyle,
  disabled,
  className,
  onClick,
}: Props) => {
  return (
    <button
      disabled={disabled}
      className={`c-blue-button ${className}`}
      style={containerStyle}
      onClick={onClick}>
      {children}
    </button>
  );
};

export default CBlueButton;
