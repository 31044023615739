import useApis from '@hooks/apis/useApis';
import { updateLocal } from '@store/local/actions';
import { RootState } from '@store/rootReducer';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function useLocalize() {
  const dispatch = useDispatch();

  const { local } = useSelector((state: RootState) => state.localReducer);

  const __updateLocal = useCallback((local: 'korean' | 'english') => {
    dispatch(updateLocal(local));
  }, []);

  return {
    local,
    __updateLocal,
  };
}
