import { useEffect, useState } from 'react';

export type WindowDimensionType = {
  width: number;
  height: number;
};

function getWindowSize(): WindowDimensionType {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowSize() {
  const [windowDimension, setWindowDimension] = useState<WindowDimensionType>(
    getWindowSize(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowDimension,
  };
}
