import text from '@assets/text';
import useLocalize from '@hooks/components/useLocalize';
import React from 'react';
import '../styles/bonus.popup.style.css';

type Props = {
  copyLink: string;
  isAnimationOn: boolean;
  translateY: number;
  onCopyButtonClicked: () => void;
  onSnsButtonClicked: (sns: 'insta' | 'twit' | 'facebook' | 'kakao') => void;
  onMouseOn: (e: React.MouseEvent) => void;
  onMouseMove: (e: React.MouseEvent) => void;
  onMouseUp: () => void;
  onTouchStart: (e: React.TouchEvent) => void;
  onTouchMove: (e: React.TouchEvent) => void;
  onTouchEnd: (e: React.TouchEvent) => void;
};

const BonusPopup = ({
  copyLink,
  isAnimationOn,
  translateY,
  onMouseMove,
  onMouseOn,
  onMouseUp,
  onTouchEnd,
  onTouchMove,
  onTouchStart,
  onCopyButtonClicked,
  onSnsButtonClicked,
}: Props) => {
  const { local } = useLocalize();

  return (
    <div
      className='bonus-popup-root'
      style={{
        transform: `translateY(${translateY}px)`,
        transition: isAnimationOn ? 'transform 0.3s ease-in-out' : 'none',
      }}>
      <div className='section-wrapper'>
        <section className='link-section'>
          <div
            className='control-pad'
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onMouseDown={onMouseOn}
            onMouseMove={onMouseMove}>
            <div className='controller'></div>
          </div>
          <div className='link'>{copyLink}</div>
          <button className='copy-button' onClick={onCopyButtonClicked}>
            {text[local].button.copy}
          </button>
        </section>
        <div className='divider'>
          <span className='share-text'>Share link via</span>
          <div className='line'></div>
        </div>
        <section className='sns-section'>
          <button
            className='insta-button'
            onClick={() => onSnsButtonClicked('insta')}
          />
          <button
            className='twit-button'
            onClick={() => onSnsButtonClicked('twit')}
          />
          <button
            className='facebook-button'
            onClick={() => onSnsButtonClicked('facebook')}
          />
          <button
            className='kakao-button'
            onClick={() => onSnsButtonClicked('kakao')}
          />
        </section>
      </div>
    </div>
  );
};

export default BonusPopup;
