import { combineReducers } from 'redux';

import popupReducer from './popup/reducer';
import quizReducer from './quiz/reducer';
import headerReducer from './header/reducer';
import recommnederReducer from './recommender/reducer';
import localReducer from './local/reducer';

const rootReducer = combineReducers({
  popupReducer,
  quizReducer,
  headerReducer,
  recommnederReducer,
  localReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
