import React from 'react';
import '../styles/progressbar.style.css';

type Props = { step: number };

const Progressbar = ({ step }: Props) => {
  return (
    <div className='progressbar-root'>
      <div className='progress' style={{ width: `${(step / 4) * 100}%` }}></div>
    </div>
  );
};

export default Progressbar;
