import React from 'react';
import './styles/dropdown.style.css';

type Props = {
  isFocused: boolean;
  options: string[];
  label: string;
  width: number;
  selectedOption: string;
  className?: string;
  containerStyle?: React.CSSProperties;
  onDropdownClicked: () => void;
  onOptionClicked: (option: string) => void;
};

const CDropdown = ({
  isFocused,
  options,
  label,
  width,
  selectedOption,
  className,
  containerStyle,
  onDropdownClicked,
  onOptionClicked,
}: Props) => {
  return (
    <div
      className={`dropdown-root ${isFocused ? 'focused' : ''} ${
        selectedOption ? 'selected' : ''
      }`}
      onClick={onDropdownClicked}>
      <span className='label'>{label}</span>
      <button
        className={`dropdown-container ${className}`}
        style={{ ...containerStyle, width }}>
        <span className='text'>{selectedOption}</span>
        <div className='arrow' />
      </button>
      <div className='options-wrapper' style={{ width }}>
        <ul className='option-list'>
          {options.map((option, index) => (
            <li
              className='option'
              key={index}
              onClick={() => onOptionClicked(option)}>
              {option}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CDropdown;
