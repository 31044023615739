import React from 'react';
import BonusContainer from '../containers/BonusContainer';
import EmailFormContainer from '../containers/EmailFormContainer';
import ProgressbarContainer from '../containers/ProgressbarContainer';
import QuizStartContainer from '../containers/QuizStartContainer';
import QuizStep1Container from '../containers/QuizStep1Container';
import QuizStep2Container from '../containers/QuizStep2Container';
import QuizStep3Container from '../containers/QuizStep3Container';
import QuizStep4Container from '../containers/QuizStep4Container';
import '../styles/quiz.layout.style.css';

type Props = {
  isKakao: boolean;
  isMobile: boolean;
  step: number;
  popupState: number;
  onPopupClosed: () => void;
  onStepBackClicked: () => void;
};

const QuizLayout = ({
  isKakao,
  isMobile,
  step,
  popupState,
  onPopupClosed,
  onStepBackClicked,
}: Props) => {
  if (step === 5 && isMobile) {
    return (
      <div className='mobile-email-form-root'>
        <EmailFormContainer />
      </div>
    );
  } else {
    return (
      <div className='quiz-layout-root'>
        <section
          className='quiz-popup'
          style={{
            transform: isMobile
              ? 'none'
              : `translateY(${100 - popupState * 100}vh)`,
            paddingTop: isKakao ? 60 : 0,
          }}>
          {isMobile && (
            <section className='title-section'>
              {(step === 1 || step === 6) && (
                <button className='m-close-btn' onClick={onPopupClosed} />
              )}
              {step > 1 && step !== 6 && (
                <button className='m-back-btn' onClick={onStepBackClicked} />
              )}
              {step !== 6 && <h2 className='title'>Take a quiz</h2>}
            </section>
          )}
          {/* progressbar */}
          {step !== 6 && <ProgressbarContainer progress={step} />}
          {/* close button */}
          {!isMobile && (step <= 1 || step >= 5) && (
            <button className='close-btn' onClick={onPopupClosed} />
          )}
          {!isMobile && step > 1 && step < 5 && (
            <button className='back-btn' onClick={onStepBackClicked} />
          )}
          {/* content */}
          <div className='content'>
            {step === 0 && <QuizStartContainer />}
            {step === 1 && <QuizStep1Container />}
            {step === 2 && <QuizStep2Container />}
            {step === 3 && <QuizStep3Container />}
            {step === 4 && <QuizStep4Container />}
            {step === 5 && <EmailFormContainer />}
            {step === 6 && <BonusContainer />}
          </div>
        </section>
        <div
          className='backdrop'
          style={{ opacity: `${popupState * 0.24}` }}></div>
      </div>
    );
  }
};

export default QuizLayout;
