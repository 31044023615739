import {
  updateCode,
  updateEmail,
  updateRecommender,
} from '@store/recommender/actions';
import { RootState } from '@store/rootReducer';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

//답변 등록 후
export default function useRecommender() {
  const { recommender, code, email } = useSelector(
    (state: RootState) => state.recommnederReducer,
  );
  const dispatch = useDispatch();

  const __updateRecommender = useCallback(
    (recommender: string) => {
      dispatch(updateRecommender(recommender));
    },
    [dispatch],
  );

  const __updateCode = useCallback(
    (code: string) => {
      dispatch(updateCode(code));
    },
    [dispatch],
  );

  const __updateEmail = useCallback(
    (email: string) => {
      dispatch(updateEmail(email));
    },
    [dispatch],
  );

  return {
    code,
    recommender,
    email,
    __updateEmail,
    __updateRecommender,
    __updateCode,
  };
}
