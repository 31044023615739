import React, { createContext, useCallback, useState } from 'react';

type Props = { children: JSX.Element };

type ContextProps = {
  popup: JSX.Element | null;
  isCloseTrigger: boolean;
  onPopupClosed: () => void;
  onPopupShow: (popup: JSX.Element) => void;
};

export const PopupContextStore = createContext<ContextProps>({
  popup: null,
  isCloseTrigger: false,
  onPopupClosed: () => {},
  onPopupShow: () => {},
});

const PopupProvider = ({ children }: Props) => {
  const [popup, setPopup] = useState<JSX.Element | null>(null);
  const [isCloseTrigger, setIsCloseTrigger] = useState(false);

  const onPopupClosed = useCallback(() => {
    setIsCloseTrigger(true);
    setTimeout(() => {
      setPopup(null);
      setIsCloseTrigger(false);
    }, 310);
  }, []);

  const onPopupShow = useCallback((popup: JSX.Element) => {
    setPopup(popup);
  }, []);

  return (
    <PopupContextStore.Provider
      value={{ popup, isCloseTrigger, onPopupClosed, onPopupShow }}>
      {children}
    </PopupContextStore.Provider>
  );
};

export default PopupProvider;
