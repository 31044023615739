import { QuizActionType } from './actions';

const initialState: QuizStateType = {
  step: 0,
};

export type QuizStateType = {
  step: number;
};

export default function reducer(
  state = initialState,
  actions: QuizActionType,
): QuizStateType {
  switch (actions.type) {
    case 'UPDATE_STEP':
      return {
        step: actions.step,
      };
    case 'NEXT_STEP':
      return {
        step: state.step + 1,
      };
    case 'PREV_STEP':
      return {
        step: state.step - 1,
      };
    default:
      return state;
  }
}
