import React, { useCallback, useContext, useEffect, useState } from 'react';
import PopupProvider, { PopupContextStore } from './PopupProvider';
import './styles/popup.module.style.css';

type Props = { children: JSX.Element };

const WithPopup = ({ children }: Props) => {
  const { popup, isCloseTrigger, onPopupClosed } =
    useContext(PopupContextStore);

  const [isAnimationDone, setIsAnimationDone] = useState(false);
  const [translateY, setTranslateY] = useState(80);

  const onBackdropClicked = useCallback(() => {
    onPopupClosed();
  }, [onPopupClosed]);

  useEffect(() => {
    if (popup) {
      setTimeout(() => setTranslateY(0), 10);
    } else {
      setTranslateY(80);
      setTimeout(() => {
        setIsAnimationDone(true);
      }, 300);
    }

    return () => {
      setIsAnimationDone(false);
    };
  }, [popup]);

  useEffect(() => {
    if (isCloseTrigger) {
      setTranslateY(80);
    }

    return () => {};
  }, [isCloseTrigger]);

  return (
    <div className='popup-module'>
      {children}
      {popup && (
        <div className='popup-container'>
          <div className='backdrop' onClick={onBackdropClicked} />
          <div
            className='popup'
            style={{ transform: `translateY(${translateY}vh)` }}>
            {popup}
          </div>
        </div>
      )}
    </div>
  );
};

export default WithPopup;
